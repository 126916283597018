import React from "react"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { graphql } from 'gatsby';
import { withI18next } from '@wapps/gatsby-plugin-i18next';
import { Section } from '../components/section';
import './index.scss';
import { Jumbotron } from '../components/Jumbotron';
import { ImageCentro } from '../components/images/centro';
import { ImageMasaje } from '../components/images/masaje';
import { ImageOsteopatia } from '../components/images/osteopatia';
import { ImageMasajeTerapeutico } from '../components/images/masaje-terapeutico';
import { useTranslation } from 'react-i18next';

export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...LocaleFragment
    }
  }
`;

const IndexPage = () => {
  const { t } = useTranslation(['messages']);

  return (
    <Layout>
      <SEO title={t('messages:h1')} description={t('messages:SEO description')}  />
      <Jumbotron />

      <Section className="HeadingIngress MarginTop(4rem)">
        <h2>{t('messages:incorpore heading')}</h2>
        <p>{t('messages:h1')}</p>
      </Section>
      
      <Section className="Content-Right MarginTop@md(2rem)">
        <div className="Image">
          <ImageCentro />
        </div>
        <div className="Content">
          <h3>{t('messages:centro heading')}</h3>
          <p>{t('messages:centro p1')}</p>
          <p>{t('messages:centro p2')}</p>
          <p className="Quote">{t('messages:centro quote')}</p>
          <h3><h3>{t('messages:centro location heading')}</h3></h3>
          <p dangerouslySetInnerHTML={{ __html: t('messages:centro p3')}} />
        </div>
      </Section>

      <Section className="HeadingIngress">
        <h2 className="MarginTop@md(2rem)">{t('messages:treatments title')}</h2>
        <p>{t('messages:treatments p1')}</p>
      </Section>

      <Section className="Content-Left">
        <div className="Content">          
          <h3 className="MarginTop@md(2rem)">{t('messages:treatment1 title')}</h3>
          <p>{t('messages:treatment1 p1')}</p>
        
          <h3 className="MarginTop(2rem)">{t('messages:treatment2 title')}</h3>
          <p>{t('messages:treatment2 p1')}</p>

        </div>
        <div className="Image">
          <ImageMasaje />
        </div>
      </Section>

      <Section className="Content-Right">
          <div className="Image">
          <ImageOsteopatia />
        </div>
        <div className="Content">
            <h3 className="MarginTop@md(2rem)">{t('messages:treatment5 title')}</h3>
            <p>{t('messages:treatment5 p1')}</p>
            
            <h3 className="MarginTop(2rem)">{t('messages:treatment6 title')}</h3>
            <p>{t('messages:treatment6 p1')}</p>
          </div>
        </Section>


      <Section className="Content-Left MarginBottom(2rem)">
        <div className="Content">
          <h3 className="MarginTop@md(2rem)">{t('messages:treatment3 title')}</h3>
          <p>{t('messages:treatment3 p1')}</p>
          
          <h3 className="MarginTop(2rem)">{t('messages:treatment4 title')}</h3>
          <p>{t('messages:treatment4 p1')}</p>
        </div>
        <div className="Image">
          <ImageMasajeTerapeutico />
        </div>
      </Section>
    </Layout>
  )
}

export default withI18next()(IndexPage)