import React from 'react';
import { useTranslation } from 'react-i18next';
import Loadable from 'react-loadable';
import { SilderImage1 } from './images/silder-image-1';

import './Jumbotron.scss';

const Image = Loadable({
  loader: () => import('./slider'),
  loading: () => <SilderImage1 className="Jumbotron__Background" />,
});

export interface JumbotronProps {

}

export const Jumbotron: React.FC<JumbotronProps> = () => {
  const { t } = useTranslation(['messages']);
  return (
    <section className="Jumbotron">
      <Image />
      <div className="Jumbotron__Heading">
        <h1>{t('messages:h1')}</h1>
        <h2>INCÓRPORE</h2>
        <small>"Mens sana incorpore sano"</small>
      </div>
    </section>
  );
};
