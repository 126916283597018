import React from 'react';
import classNames from 'classnames';

import './section.scss';

interface SectionProps {
  className?: string;
  children: any;
}

export const Section: React.FC<SectionProps> = ({ children, className }) => {
  const classes = classNames('Section', className)
  return (
    <section className={classes}>
      {children}
    </section>
  )
}
